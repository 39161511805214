import each from 'lodash/each';
import PropTypes from 'prop-types';
import React from 'react';


/* ------ = Collections = --------------------------------------------------------------------- */

/**
 * Each component to iterate through object inline.
 *
 * Should this go within components?
 */
let propTypes = {
  object: PropTypes.object.isRequired,
  render: PropTypes.func.isRequired
}
const Each = p => {

  return(

    <React.Fragment>
      { Object.keys( p.object ).map( key => (
        p.render( key, p.object[key] )
      ) ) }
    </React.Fragment>

  )

}
Each.propTypes = propTypes;
export { Each };

/**
 * Switch component to switch condition and render.
 */
propTypes = {
  value: PropTypes.string,
  cases: PropTypes.object
}
const Switch = p => p.cases[p.value];
export { Switch };

/* ------ = Styling = --------------------------------------------------------------------- */

/**
 * Lighten color.
 *
 * Replicates the native sass functions.
 *
 * @see https://css-tricks.com/snippets/javascript/lighten-darken-color/
 *
 * @param {string}  color
 * @param {int}     amount   Can be positive for lightening and negative for darkening,
 * @return string.
 */
export const lightenColor = ( color, amount ) => {

    var usePound = false;

    if (color[0] == "#") {
        color = color.slice(1);
        usePound = true;
    }

    var num = parseInt(color,16);

    var r = (num >> 16) + amount;

    if (r > 255) r = 255;
    else if  (r < 0) r = 0;

    var b = ((num >> 8) & 0x00FF) + amount;

    if (b > 255) b = 255;
    else if  (b < 0) b = 0;

    var g = (num & 0x0000FF) + amount;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);

}

/* ------ = String parsing = --------------------------------------------------------------------- */


/**
* Checks a string for valid JSON
*
* @param {string} string
* @return JSON | bool
*
* @see https://stackoverflow.com/questions/3710204/how-to-check-if-a-string-is-a-valid-json-string-in-javascript-without-using-try/3710226#answer-20392392
*/
export const validateJSON = ( string ) => {

  try {

    let valid_json = JSON.parse( string );

    if( valid_json && typeof valid_json === 'object' ) {
      return valid_json;
    }

  }
  catch( error ){}

  return false;

}

/**
* Captilize Strings
*
* @see https://www.codetuts.tech/title-case-capitalize-sentence/
*/
export const capitalize = ( string ) => {

  if( ! string.length ) {
    return;
  }
  else {
    return string[0].toUpperCase() + string.slice( 1 );
  }

}

/**
 * Slugify a string.
 *
 * @param {string} string
 * @return string.
 */
export const slugify = string => {

  const a = 'àáäâãåèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;'
  const b = 'aaaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------'
  const p = new RegExp(a.split('').join('|'), 'g')

  return string.toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text

}

/* ------ = Misc. = --------------------------------------------------------------------- */


/**
* Get query params.
*
* @return Object.
*/
export const getQueryParams = () => {

  if( typeof window === 'undefined' ) {
    return;
  }

  const hashes = window.location.href.slice( window.location.href.indexOf( '?' ) + 1 ).split( '&' );
  const params = {}

  for( let i = 0; i < hashes.length; i++ ) {

    const hash = hashes[i].split( '=' );
    params[hash[0]] = hash[1];

  }

  return params;

}

/**
 * Check for empty objects.
 *
 * @see https://stackoverflow.com/questions/679915/how-do-i-test-for-an-empty-javascript-object
 * @param {Object} obj
 * @return bool.
 */
export const isEmpty = ( obj ) => {

  if(
    ! obj ||
    typeof obj == 'undefined' ||
    ( Object.keys( obj ).length === 0 && obj.constructor === Object )
  ) {
    return true;
  }
  else {
    return false;
  }

}



/**
 * Breakpoint goodness.
 */
export const breakpoints = {

  sizes: {
    xl:     '1200',
    lg:     '992',
    md:     '768',
    sm:     '576',
    xs:     '0',
  },
  xsUp: function() {
    if( typeof window === 'undefined' ) {
      return;
    }
    return window.matchMedia( '(min-width: ' + this.sizes.xs + 'px)' ).matches;
  },
  smUp: function() {
    if( typeof window === 'undefined' ) {
      return;
    }
    return window.matchMedia( '(min-width: ' + this.sizes.sm + 'px)' ).matches;
  },
  mdUp: function() {
    if( typeof window === 'undefined' ) {
      return;
    }
    return window.matchMedia( '(min-width: ' + this.sizes.md + 'px)' ).matches;
  },
  lgUp: function() {
    if( typeof window === 'undefined' ) {
      return;
    }
    return window.matchMedia( '(min-width: ' + this.sizes.lg + 'px)' ).matches;
  },
  xlUp: function() {
    if( typeof window === 'undefined' ) {
      return;
    }
    return window.matchMedia( '(min-width: ' + this.sizes.xl + 'px)' ).matches;
  }

}

/**
 * Determine breakpoint.
 */
export const getBreakpoint = () => {

  if( typeof window === 'undefined' ) {
    return;
  }

  const width = window.innerWidth;
  let breakpoint = null;

  for( let key in breakpoints.sizes ) {

    if( width >= breakpoints.sizes[key] ) {
      breakpoint = key;
      break;
    }
  }

  return breakpoint;

}

/**
 * Generate unique key.
 *
 * @param {int} length
 */
export const generateUniqueKey = length => {

  const possible = 'abcdefghijklmnopqrstuvwxyz';
  let key = '';

  for( let i = 0; i < length; i++ ) {
    key += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return key;

}
