import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useSetState } from 'react-use';

import { getBreakpoint } from 'lib/helpers';

const AppContext = React.createContext();

export const AppContextProvider = ( { children } ) => {


  /* ------ = Lifecycle = --------------------------------------------------------------------- */
  const initBreakpoint = getBreakpoint();
  const [ s, setState ] = useSetState( {
    bgShade: 'light',
    isShowingMobileMenu: false,
    isTransitioning: false,
    // siteMeta: siteMeta,
    breakpoint: initBreakpoint,
    sermonPlayer: {
      isShowing: false,
      sermonPlaying: null,
    },
    previewData: {}, // Post IDs with live updates.
  } );
  s.setState = setState;


  React.useEffect( () => {

    window.addEventListener( 'pageChange', () => {

      setState( {
        isShowingMobileMenu: false
      } );

    } );

  }, [] );


  /* ------ = Output = --------------------------------------------------------------------- */
  return(
    <AppContext.Provider value={ { ...s, setState  } }>
      { children }
    </AppContext.Provider>
  );

}

export default AppContext;