import each from 'lodash/each';
import ReactBlockContent from '@sanity/block-content-to-react';
import React from 'react';
import PropTypes from 'prop-types';

/**
 * BlockContent.
 */
const BlockContent = p => {

  // const serializers = {
  //   types: {
  //     block: props => {
  //
  //       if( props.style === 'normal' ) {
  //
  //
  //       }
  //
  //
  //     }
  //   }
  // }
  // console.log( p.blocks );
  // if( p.blocks ) {
  //
  //   p.blocks.markDefs: [
  //     {
  //         _type: 'footnote'
  //     }
  //   ]
  // }
  const serializers = {
    marks: {
      footnote: ( { children: num } ) => (
        <sup>{ num }</sup>
      )
    }
  }

  // Basic placeholders.
  if( p.placeholders ) {

    let blockIndex = 0;
    for( let block of p.blocks ) {

      let childIndex = 0;
      for( let child of block.children ) {

        each( p.placeholders, ( replace, find ) => {
          child.text = child.text.replace( new RegExp( `${find}`, 'gi' ), replace );
          console.log( replace );
          console.log( find );
          console.log( child.text );
        } );

        block.children[childIndex] = child;

        childIndex++;
      }

      p.blocks[blockIndex] = block;

      blockIndex++;

    }

  }


  return(
    <ReactBlockContent
    projectId={ process.env.SANITY_PROJECT }
    dataset={ process.env.SANITY_DATASET }
    blocks={ p.blocks }
    serializers={ serializers }
    />
  );

};

export default BlockContent;
