import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import { Squeeze } from 'react-burgers';
import { FaFacebookF, FaYoutube } from 'react-icons/fa';

import AppContext from 'AppContext';
import BlockContent from 'molecules/BlockContent';
import Container from 'molecules/Container';
import Link from 'molecules/Link';

import header from 'data/globals/header';
import settings from 'data/globals/settings';

import './Header.scss';

/**
 * Header.
 */
const propTypes = {

}
const Header = p => {

  const AppState = React.useContext( AppContext );
  const data = null;

  /* ------ = Subcomponents. = --------------------------------------------------------------------- */

  /**
   * Social icons.
   */
  const SocialIcons = () => (

    <>
      { !! settings.social &&

        <ul className="Hder__social inlinelist">
          { settings.social.map( ( item, key ) => (

            <li className="Hder__social-item">
              <a
              className="d-block"
              href={ item.url }
              target="__blank"
              >
              { item.name === 'facebook'

                ? <FaFacebookF />
                : item.name === 'youtube' ? <FaYoutube />
                : null
              }
              </a>
            </li>

          ) ) }
        </ul>

      }
    </>
  )


  /* ------ = Output. = --------------------------------------------------------------------- */
  const className = classnames(
    'Hder',
  );

  return(

    <Container className={ className }>

      { /* Top */ }

      { /* Nav */ }
      <div className="Hder__nav">

        { /* Logo */ }
        <Link
        className="Hder__nav-logo"
        to={ '/' }
        >
          <img
          src={ settings.logo.url }
          />
        </Link>

        { /* Top Text */ }
        <div className="Hder__top-meta d-none d-md-block"
        >
          <BlockContent blocks={ header.topText } />
          <div className="top-social">
            <SocialIcons />
          </div>
        </div>


        { /* Menu */ }
        <Squeeze
        className="Hder__nav-burger d-md-none"
        active={ !! AppState ? AppState.isShowingMobileMenu : null }
        color="white"
        lineHeight={ 2 }
        lineSpacing={ 8 }
        width={ 28 }
        onClick={ () => {
          if( ! AppState ) {
            return;
          }
          AppState.setState( { isShowingMobileMenu: ! AppState.isShowingMobileMenu } )
        } }
        />
        <div className="Hder__mobile-content d-md-none">
          <span className="close">Close</span>
          
          <div className="top-text">
            <BlockContent blocks={ header.topText } />
          </div>
        </div>
        <nav className="Hder__nav-menu">
          <ul className="mainmenu cleanlist">
            { !! header.primaryMenu.length && header.primaryMenu.map( ( item, key ) => (

              <li
              key={ key }
              className="Hder__nav-item">

                { !! item.dropdownOption

                  ?
                    <>
                      <span className="text">
                        { item.text }
                      </span>
                      <ul className="submenu cleanlist">
                        { item.dropdown.map( ( subItem, key ) => (

                          <li
                          key={ key }
                          >
                            <Link
                            className="Hder__nav-sub-item"
                            to={ subItem.page.slug.current }
                            >
                              { subItem.text }
                            </Link>
                          </li>

                        ) ) }
                      </ul>
                    </>

                  :
                    <Link
                    className="text"
                    to={ item.page.slug.current }
                    >
                      { item.text }
                    </Link>

                }


              </li>

            ) ) }

            <li className="Hder__nav-bottom-social d-md-none">
              <SocialIcons />
            </li>

          </ul>
        </nav>

      </div>

    </Container>

  )

};
Header.propTypes = propTypes;

export default Header;
