import classnames from 'classnames';
import Helmet from 'react-helmet';
import React from 'react';

import 'lib/global.scss';
import Footer from 'organisms/Footer';
import Header from 'organisms/Header';
import RefTagger from 'molecules/RefTagger';
import SermonPlayer from 'organisms/SermonPlayer';

import AppContext from 'AppContext';

/**
 *  The App.
 */
const App = React.memo( ( { children } ) => {

  const AppState = React.useContext( AppContext );

  /* ------ = Output. = --------------------------------------------------------------------- */
  const className = classnames( {
    ['is-showing-menu']: AppState.isShowingMobileMenu,
  } );

  React.useEffect( () => {
    console.log( 'APP MOUNTED' );
  }, [] );

  return(

    <div className={ className }>

      { /* Header */ }
      <header>
        <Header />
      </header>

      { /* Body */ }
      <main role="main">
        { children }
        <SermonPlayer />
      </main>

      { /* Footer */ }
      <footer>
        <Footer />
      </footer>

      <RefTagger />

    </div>

  );

} );

export default App;
