import React from 'react';
import { Link as GatsbyLink } from 'gatsby';

import AppContext from 'AppContext';

/**
 * Link.
 *
 * Modified Gatsby link for local urls.
 * @see https://www.gatsbyjs.org/docs/gatsby-link/ for props and details.
 */
const Link = ( { children, className, target, to, ...gatsbyProps } ) => {

  const AppState = React.useContext( AppContext );
  let modifiedTo = to;

  if( target === '_blank' ) {

    return(
      <a
      className={ className }
      href={ modifiedTo }
      target={ target }
      rel="noopener noreferrer"
      >
        { children }
      </a>
    );

  }

  if( ! modifiedTo.startsWith( '/' ) ) {
    modifiedTo = `/${modifiedTo}`
  }


  return(

    <GatsbyLink
    className={ className }
    to={ modifiedTo }
    { ...gatsbyProps }
    >
      { children }
    </GatsbyLink>

  );

}
export default Link;