import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import { FaFacebookF, FaYoutube } from 'react-icons/fa';

import BlockContent from 'molecules/BlockContent';
import Container from 'molecules/Container';

import header from 'data/globals/header';
import settings from 'data/globals/settings';
import './Footer.scss';

/**
 * Footer.
 */
const Footer = p => {

  const data = null;
  // const data = useStaticQuery( graphql`
  //   query {
  //     settings: sanitySettings {
  //       social: _rawSocial(resolveReferences: {maxDepth: 10})
  //       logo: _rawLogo(resolveReferences: {maxDepth: 10})
  //       primary_email
  //       primary_phone
  //     }
  //     others: sanityHeader {
  //       topText
  //     }
  //   }
  // `);

  /* ------ = Output. = --------------------------------------------------------------------- */
  const className = classnames(
    'Footer',
  )

  return(

    <div className={ className }>
      <Container>

        <div className="row align-items-center">

        <div className="Footer__social order-md-8 col-md-4">
          { !! settings.social && settings.social.map( ( item, key ) => {

            return (

              <a
              className="d-inline-block"
              href={ item.url }
              target="__blank"
              >
              { item.name === 'facebook'

                ? <FaFacebookF />
                : item.name === 'youtube' ? <FaYoutube />
                : null
              } }
              </a>

            )

          } ) }
        </div>

        <div className="Footer__address order-md-1 col-md-4">
          <div className="Footer__address-times">
            <BlockContent blocks={ header.topText } />
          </div>
          <div className="Footer__address-street">
          </div>
          <div className="Footer__address-contact">
            <div>
              <a href={ `tel:+1${settings.primary_phone}` }>
                { settings.primary_phone }
              </a>
            </div>
            <div>
              <a href={ `mailto:${settings.primary_email}` }>
                { settings.primary_email }
              </a>
            </div>
          </div>

        </div>

        <div className="Footer__logo order-md-4 col-md-4">
          <img src={ settings.logo.url } />
        </div>

      </div>

      </Container>
    </div>

  )

};

export default Footer;
