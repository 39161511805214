import React from 'react';
import BootTooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { generateUniqueKey } from 'lib/helpers';

const Tooltip = ( { children, placement, tip, ...p } ) => {

  const key = generateUniqueKey( 3 );

  return(

    <OverlayTrigger
    key={ key }
    placement={ placement || 'top' }
    overlay={
      <BootTooltip
      className="Tooltip"
      id={ `tooltip-${key}` }
      { ...p }
      >
        { tip }
      </BootTooltip>
    }
    >
      { children }
    </OverlayTrigger>

  );

}
export default Tooltip;

