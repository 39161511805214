import React from 'react';
//import * as script from './script';

import './RefTagger.scss';

const RefTagger = React.memo( p => {


  React.useEffect( () => {

    window.refTagger = {
      settings: {
        bibleVersion: "NASB",
      }
    }
    const holder = document.getElementById( 'reftagger-holder' );
    const script = document.createElement( 'script' );

    script.src = '//api.reftagger.com/v2/RefTagger.js';

    holder.appendChild( script );

  }, [] );


  return(

    <div
    id="reftagger-holder"
    >

    </div>
  )

} );
export default RefTagger;