/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react';

import App from './src/App';
import { AppContext, AppContextProvider } from './src/AppContext';


/**
 * Wrapping root element with global providers.
 *
 */
export const wrapRootElement = ( { element } ) => (

  <AppContextProvider>
    { element }
  </AppContextProvider>

);


/**
 * Wrapping html with Layout that persists between page transitions.
 */
export const wrapPageElement = ( { element, p } ) => (

  <App>
    { element }
  </App>

);

/**
 * Route updates.
 */
export const onRouteUpdate = ( { location, prevLocation } ) => {

  if( ! window.refTagger || ! window.refTagger.tag ) {
    return;
  }

  // Track page changes.
  window.dispatchEvent( new CustomEvent( 'pageChange' ) );

  if( process.env.ACTIVE_ENV === 'development' ) {

    window.setTimeout( () => {

      if( ! window.refTagger.tag ) {
        return;
      }
      window.refTagger.tag( document.getElementById( '__gatsby' ) );

    }, 1000 );
  }
  else {

    window.setTimeout( () => {
      window.refTagger.tag( document.getElementById( '__gatsby' ) );
    }, 1000 );

  }




}
