import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { MdClose, MdLaunch } from 'react-icons/md';
import { FaHeadphonesAlt } from 'react-icons/fa';

import AppContext from 'AppContext';
import Tooltip from 'molecules/Tooltip';
import './SermonPlayer.scss';

/**
 * Sermon player */
const propTypes = {
  isShowing: PropTypes.bool,
  sermonPlaying: PropTypes.int
}
const SermonPlayer = p => {

  const AppState = React.useContext( AppContext );

  const className = classnames( 'SermonPlayer', {
    ['is-showing']: AppState.sermonPlayer.isShowing,
  } );

  return (

    <div
    className={ className }
    >
      <Tooltip tip={ AppState.sermonPlayer.isShowing ? 'Minimize' : 'Show sermon player' }
      >
        <button
        className="SermonPlayer__btn"
        onClick={ () => AppState.setState( { sermonPlayer: { isShowing: ! AppState.sermonPlayer.isShowing } } ) }
        >
          {
            AppState.sermonPlayer.isShowing

            ? <MdLaunch className="close" />

            : <FaHeadphonesAlt className="icon"/>

          }
        </button>
      </Tooltip>
      <iframe
      className="SermonPlayer__iframe"
      tabIndex="-1"
      width="1"
      height="360"
      src="https://embed.sermonaudio.com/browser/broadcaster/redmillsbaptist/?sort=newest&page_size=10&style=compact&header=false&dark=true&external_borders=false"
      style={ {
        minWidth: '100%',
        maxWidth: '100%',
      } }
      allow="autoplay"
      scrolling="no"
      />
    </div>


  )
}
export default SermonPlayer;