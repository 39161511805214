// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-category-index-js": () => import("./../../../src/pages/blog/category/index.js" /* webpackChunkName: "component---src-pages-blog-category-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-templates-pages-contact-contact-js": () => import("./../../../src/templates/pages/Contact/Contact.js" /* webpackChunkName: "component---src-templates-pages-contact-contact-js" */),
  "component---src-templates-pages-default-default-js": () => import("./../../../src/templates/pages/Default/Default.js" /* webpackChunkName: "component---src-templates-pages-default-default-js" */),
  "component---src-templates-pages-home-home-js": () => import("./../../../src/templates/pages/Home/Home.js" /* webpackChunkName: "component---src-templates-pages-home-home-js" */),
  "component---src-templates-posts-post-post-js": () => import("./../../../src/templates/posts/Post/Post.js" /* webpackChunkName: "component---src-templates-posts-post-post-js" */)
}

